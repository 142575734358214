const DEFAULT_CONFIG = {
	//接口地址
	API_URL: "http://192.168.3.8:8005/api/v1",   //郑意辉
    // API_URL: "http://192.168.3.10:8005/api/v1",   //姚洁成
    // 请求服务器地址
    // API_URL: 'http://192.168.3.17:8005/api/v1',   //压力
    // API_URL: 'https://web.tranthing.com/api/v1',   //生产
    // API_URL: 'http://192.168.3.176:8005/api/v1',   //预生产
    // API_URL: 'https://uat.tranthing.com/api/v1',   //云预生产

	// API_URL: "http://192.168.3.10:8000/",

	// 静态资源接口
	STATIC_URL:"http://192.168.3.8:8005", //郑意辉
	// STATIC_URL:"http://192.168.3.10:8000", //姚洁成 
	// STATIC_URL:"http://192.168.3.8:8005",

    STATIC_LIVE: "https://192.168.3.92/index/api/webrtc?app=live",
    // STATIC_LIVE: "https://192.168.3.92:1935/live/",

    STATIC_PAY_URL: "https://uat.tranthing.com/h5/#/pages/pay/pay?", // 预生产测试  
    // STATIC_PAY_URL: "https://web.tranthing.com/h5/#/pages/pay/pay?", // 生产

	STATIC_LIVE_URL :"rtmp://192.168.3.92:1935/live/",

	FD1014G_URL:"http://192.168.3.10:9849",


	//后台主题
	THEME:"light",
	// THEME:"dark",

	MQTT_CLINET:null
}

// 如果生产模式，就合并动态的APP_CONFIG
// public/config.js
if(process.env.NODE_ENV === 'production'){
	Object.assign(DEFAULT_CONFIG, APP_CONFIG)
}
module.exports = DEFAULT_CONFIG