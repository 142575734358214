<template>
    <el-row :class="[layoutChange == false ? 'box-view-active' : 'box-view']">

        <el-col :xs="0">
            <MenuLeft @getMenuName="getMenuName" v-if="layoutChange"></MenuLeft>
        </el-col>


        <el-col class="box-view-content" :xs="24">


            <MenuHeader ref="MenuHeader" :layoutChange="layoutChange" style="position:sticky;top:0"></MenuHeader>
            <!-- <div style="height: 34px;width:100%;background-color: red;">
  
              </div> -->
            <MneuTab></MneuTab>
            <div class="content-bg">
                <div style="height:10px">
                </div>
                <div class="all-content">

                    <!-- transition: vue 内置动画标签, 在 [插入] / [更新] / [移除] DOM 元素时，在合适的时候给元素添加样式类名 -->
                    <transition name="fade" mode="out-in">
                        <keep-alive>
                            <router-view :key="$route.name" v-if="!$route.meta.keepAlive"> </router-view>
                        </keep-alive>
                    </transition>

                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import MenuLeft from '@/components/layout/menuleft.vue';
import MenuHeader from '@/components/layout/menuheader.vue';
import MneuTab from '@/components/layout/menuBread.vue'
export default {
    name: 'HomeMenu',
    components: {
        MenuLeft,
        MenuHeader,
        MneuTab,
    },
    data() {
        return {
            layoutChange: true,
            drawer: false,
            // isCollapse:false,
        }
    },
    methods: {
        theme(type) {
            this.$store.commit('upDate', { themeType: type });
            window.document.documentElement.setAttribute("data-theme", 'dark');
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        getMenuName(val) {
            this.$refs.MenuHeader.transfer(val)
        }
    },
    mounted() {
        // window.document.documentElement.setAttribute( "data-theme", 'light' );
        // routes = this.$router.options.routes

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme/handle";

* {
    padding: 0;
    margin: 0;
}

.box-view {
    // width: 100%;
    height: 100vh;
    display: flex;

    .box-view-content {
        min-width: calc(100% - 240px);
        width: 100%;
        height: 100%;

        .home-menu-header {
            width: 100%;
            height: 6%;
            @include background_color('background_color1')
        }

        .content-bg {
            // height: calc(100% - 100px);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            justify-content: center;
            flex-direction: column;

            .tag {
                width: 98%;
                height: 40px;
                margin-top: 10px;
                background-color: #ffffff;
                margin-bottom: 5px;
            }

            .all-content {
                width: 98%;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                // @include background_color('background_color1');

            }
        }
    }
}

.box-view-active {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .box-view-content {
        width: calc(100% - 240px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content-bg {
            margin: auto;
            width: 1550px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include background_color('background_color3');
            justify-content: center;
            flex-direction: column;

            .tag {
                width: 100%;
                height: 40px;
                margin-top: 20px;
                background-color: #ffffff;
                margin-bottom: 5px;
            }

            .all-content {
                width: 100%;
                height: 95vh;
                // margin-bottom: 20px;
                @include background_color('background_color1');

            }
        }
    }
}

.setUP {
    width: 60px;
    height: 60px;
    position: fixed;
    background-color: rgb(103, 182, 216);
    right: 2px;
    top: 200px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #ffffff;
    transition: all 0.3s;
    z-index: 100000;
}

.setUP-active {
    width: 60px;
    height: 60px;
    position: fixed;
    background-color: rgb(103, 182, 216);
    right: 350px;
    top: 200px;
    border-radius: 15px 0 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #ffffff;
    z-index: 100000;
    transition: all 0.3s;
}

::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background-color: rgb(240, 242, 245);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: rgb(132, 133, 135);
    // opacity: 0.2;
    // background: fade(@primary-color, 60%);
}

::-webkit-scrollbar-track {

    border-radius: 0;
}
</style>